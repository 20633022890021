import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../account/auth.service';
import { AlertService } from '../../_shared/services/alert.service';
import { AppUserService } from '../../_shared/services/app-user.service';
import { AppUser } from './appuser.module';
import { AppUser as AppUser2 } from '../../_shared/business-objects/app-user.bo';

@Component({
  selector: 'app-app-user-update',
  templateUrl: './app-user-update.component.html',
  styleUrls: ['./app-user-update.component.css']
})
export class AppUserUpdateComponent implements OnInit {

  @Input() userId: string;
  @Input() appUser: AppUser;

  @Output() appUserCancelled = new EventEmitter<boolean>();
  @Output() appUserUpdated = new EventEmitter<AppUser2>();


  form: FormGroup;
  loading = false;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private appUserService: AppUserService,
    private alertService: AlertService
  ) {
    // redirect to home if already logged in
    //  if (!this.authService.isAuthenticated()) {
    //    this.router.navigate(['/login']);
    //  }
  }

  ngOnInit() {
    this.loadData();



    this.form.value.token = localStorage.getItem("txtToken");
  }

  // convenience getter for easy access to form fields
  get f() { return this.form.controls; }

  async loadData() {

    this.form = this.formBuilder.group({
      txtFirstName: ['', Validators.required],
      txtLastName: ['', Validators.required],
      txtUserName: ['', Validators.required],
      txtFromAddresses: ['', Validators.required],
      txtPassword: ['', [Validators.required, Validators.minLength(6)]],
      blnHas365Account: [''],
      blnHasReckonAccount: ['']
    });

    if (this.appUser == null) {
      await this.alertService.openSnackBarCustomPromise("No User", "There is no user", "Ok", "", "center", "bottom", "", 0, false);
      return;
    }

    this.form.controls['txtFirstName'].setValue(this.appUser.txtFirstName);
    this.form.controls['txtLastName'].setValue(this.appUser.txtLastName);
    this.form.controls['txtUserName'].setValue(this.appUser.txtUserName);
    this.form.controls['txtFromAddresses'].setValue(this.appUser.txtFromAddresses);
    this.form.controls['blnHas365Account'].setValue(this.appUser.blnHas365Account);
    this.form.controls['blnHasReckonAccount'].setValue(this.appUser.blnHasReckonAccount);


  }

  async updateAppUser() {
    let firstName: string = this.form.controls['txtFirstName'].value;
    let lastName: string = this.form.controls['txtLastName'].value;
    let userName: string = this.form.controls['txtUserName'].value;
    let fromAddresses: string = this.form.controls['txtFromAddresses'].value;
    let has365Account: boolean = this.form.controls['blnHas365Account'].value == true;
    let hasReckonAccount: boolean = this.form.controls['blnHasReckonAccount'].value == true;

    if (firstName == null || firstName == "") {
      this.alertService.openSnackBar("Please enter a first name.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (lastName == null || lastName == "") {
      this.alertService.openSnackBar("Please enter a last name.", "Close", "center", "bottom", "", 3000);
      return;
    }

    if (userName == null || userName == "") {
      this.alertService.openSnackBar("Please enter a user name.", "Close", "center", "bottom", "", 3000);
      return;
    }

    //console.log("this user: ", this.appUser);

    let appUser: AppUser2 = await this.appUserService.getAppUserPromise(this.appUser.rowguid);
    appUser.txtFirstName = firstName;
    appUser.txtLastName = lastName;
    appUser.txtUserName = userName;
    appUser.txtFromAddresses = fromAddresses;
    appUser.blnHas365Account = has365Account;
    appUser.blnHasReckonAccount = hasReckonAccount;

    //console.log("appUser", appUser);

    appUser = await this.appUserService.updateAppUserPromise(appUser);

    this.appUserUpdated.emit(appUser);

  }

  cancelAppUser() {
    this.appUserCancelled.emit(true);
  }


  onSubmit() {
    //this.submitted = true;

    //// reset alerts on submit
    ////this.alertService.clear();

    //// stop here if form is invalid
    //if (this.form.invalid) {
    //  return;
    //}

    //this.loading = true;

    //var user = new AppUser()
    //user.txtUserName = this.form.value.username;
    //user.txtPassword = this.form.value.password;
    //user.txtFirstName = this.form.value.firstName
    //user.txtLastName = this.form.value.lastName

    //this.authService.registerUser(user)
    //  .subscribe(
    //    data => {
    //      //this.alertService.success('Registration successful', true);
    //      this.router.navigate(['/login']);
    //    },
    //    error => {
    //      //this.alertService.error(error);
    //      console.log("error: " + error.message);
    //      this.loading = false;
    //    });
  }

}
