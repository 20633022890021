import { trigger, state, style, transition, animate, keyframes } from '@angular/animations';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-spinner-inline-tick',
  templateUrl: './spinner-inline-tick.component.html',
  styleUrl: './spinner-inline-tick.component.scss',
  animations: [
    trigger('displaySpinnerTick', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('700ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms', style({ opacity: 0 }))
      ])
    ]),
    trigger('displayTick', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('100ms', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('3000ms', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class SpinnerInlineTickComponent {

  @Input() showSpinner: boolean;
  @Input() showTick: boolean;
  @Input() diameter: number;
  @Input() inlineSpinnerId: string = ""
  @Input() spinnerClass: string;
  @Output() spinnerStatus = new EventEmitter<boolean>();
  @Output() tickStatus = new EventEmitter<boolean>();


  constructor() { }

  ngOnInit(): void {

    if (this.diameter == null) {
      this.diameter = 30;
    }

    if (this.inlineSpinnerId == null || this.inlineSpinnerId == "") {
      this.inlineSpinnerId = "inlineSpinnerId";
    }

  }

  setTick(val: boolean) {
    this.showTick = val;
    console.log("tick: ", this.showTick);

  }

}
