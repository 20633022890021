<div class="stock-item-table-list-container">
  <table mat-table #tblCurrentStockItems [dataSource]="datasource" multiTemplateDataRows class="mat-elevation-z8 stock-item-table" matSort>

    <ng-container matColumnDef="txtProductName">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH" mat-sort-header> Product </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemProduct"> {{stockitem.txtProductName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtProfileName">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH" mat-sort-header> Profile </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemProfile"> {{stockitem.txtProfileAbbrievation}} </td>
    </ng-container>

    <ng-container matColumnDef="intWidth">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemWidthTH alignRightTH" mat-sort-header> Width </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemWidth alignRightTD"> {{stockitem.intWidth}} </td>
    </ng-container>

    <ng-container matColumnDef="intThickness">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemThicknessTH" mat-sort-header> Thick </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemThickness"> {{stockitem.intThickness}} </td>
    </ng-container>

    <ng-container matColumnDef="txtSpeciesName">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemSpeciesTH" mat-sort-header> Species </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemSpecies"> {{stockitem.txtSpeciesName}} </td>
    </ng-container>

    <ng-container matColumnDef="txtGradeName">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemGradeTH" mat-sort-header> Grade </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemGrade"> {{stockitem.txtGradeName}} </td>
    </ng-container>

    <ng-container matColumnDef="fltQuantity">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemQuantityTH alignRightTH" mat-sort-header> Quantity </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemQuantity alignRightTD"> {{RoundQuantity(stockitem.fltQuantity)}} </td>
    </ng-container>

    <ng-container matColumnDef="txtQuantityUoM">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemQuantityUoMTH" mat-sort-header> Quantity UoM </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemQuantityUoM"> {{stockitem.txtQuantityUoM}} </td>
    </ng-container>

    <ng-container matColumnDef="intTotalLM">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemLengthTH alignRightTH" mat-sort-header> Length </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemLength alignRightTD"> {{stockitem.txtQuantityUoM.trim() === "Each" ? stockitem.intTotalLM : ""}} </td>
    </ng-container>

    <ng-container matColumnDef="fltDia">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemDiaTH alignRightTH" mat-sort-header> Dia </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemDia alignRightTD"> {{stockitem.fltDia}} </td>
    </ng-container>

    <ng-container matColumnDef="txtLocation">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemLocationTH" mat-sort-header> Location </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemLocation"> {{stockitem.txtContainerLocation}} </td>
    </ng-container>

    <ng-container matColumnDef="intIdentifier">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemIdentifierTH alignRightTH" mat-sort-header> Identifier </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemIdentifier alignRightTD"> {{stockitem.intIdentifier}} </td>
    </ng-container>

    <ng-container matColumnDef="dteDateCreated">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemCreatedTH alignRightTH" mat-sort-header> Created </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemCreated alignRightTD"> {{stockitem.dteDateCreated | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="txtTradePrice">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemTradePriceTH alignRightTH" mat-sort-header> Trade Price </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemTradePrice alignRightTD"> ${{stockitem.txtTradePrice}} </td>
    </ng-container>

    <ng-container matColumnDef="txtRetailPrice">
      <th mat-header-cell id="fltRetailPriceTH" *matHeaderCellDef class="stockitemTH stockItemRetailPriceTH alignRightTH" mat-sort-header> Retail Price </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemRetailPrice alignRightTD"> ${{stockitem.txtRetailPrice}} </td>
    </ng-container>

    <ng-container matColumnDef="txtApprovedPrice">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemApprovedPriceTH alignRightTH" mat-sort-header> Approved Price </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD alignRightTD" [ngClass]="stockitem.fltApprovedPrice > 0 ? 'stockItemApprovedPriceRed' : 'stockItemApprovedPrice'">{{stockitem.fltApprovedPrice ? '$' + stockitem.txtApprovedPriceText : ''}}</td>
    </ng-container>
    <ng-container matColumnDef="txtStockKeepingUnit">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemSKUTH" mat-sort-header> SKU </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemSKU">
        {{
stockitem.txtStockKeepingUnit
        }}
        <mat-icon matTooltip="Copy To Clipboard" class="help-icon small-icon" (click)="copyToClipboard(stockitem.txtStockKeepingUnit)">content_copy</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnEditStockItem">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemEditTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemEdit">
        <button class="edit-stock-item-button" type="button" mat-flat-button color="primary" (click)="editStockItem(stockitem)">Edit</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnAddStockItem">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemAddTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemAdd">
        <button class="add-stock-item-button" type="button" mat-flat-button color="primary" (click)="addStockItem(stockitem)">Add Item</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnPrintSKUBarcode">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemAddTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemPrintSKUBarcode">
        <button class="add-stock-item-button" type="button" mat-flat-button color="primary" (click)="printSKUBarcode(stockitem, 'large')">Print Barcode Large</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnPrintSKUBarcodeSmall">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemAddTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemPrintSKUBarcode">
        <button class="add-stock-item-button" type="button" mat-flat-button color="primary" (click)="printSKUBarcode(stockitem, 'small')">Print Barcode Small</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnPrintSKUBarcodeExtraLarge">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemAddTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemPrintSKUBarcode">
        <button class="add-stock-item-button" type="button" mat-flat-button color="primary" (click)="printSKUBarcodeExtraLarge(stockitem)">Print Barcode Extra Large</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnTakePhoto">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemTakePhotoTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemTakePhoto">
        <button type="button" mat-flat-button color="primary" (click)="fileInput.click()">Take Photo</button>
        <input hidden (change)="savePhoto($event, stockitem)" #fileInput type="file" id="file" accept="image/*" capture="environment">
      </td>
    </ng-container>

    <ng-container matColumnDef="btnViewPhotos">
      <th mat-header-cell *matHeaderCellDef class="stockitemTH stockItemViewPhotoTH" mat-sort-header>  </th>
      <td mat-cell *matCellDef="let stockitem" class="stockitemTD stockItemViewPhoto">
        <button type="button" mat-flat-button color="primary" (click)="viewPhotos(stockitem)">View Photos</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnViewStockItemHistory">
      <th mat-header-cell *matHeaderCellDef class="stockItemViewPhotoTH" aria-label="row actions"></th>
      <td mat-cell *matCellDef="let stockitem; let rowIdx = dataIndex" class="stockitemTD">
        <button mat-icon-button aria-label="expand row" (click)="expandElement(stockitem, rowIdx);$event.stopPropagation()">
          <mat-icon *ngIf="stockItemExpanded !== stockitem">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="stockItemExpanded === stockitem">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <th mat-header-cell *matHeaderCellDef class="stockItemViewPhotoTH" [attr.colspan]="displayedStockItems.length"></th>
      <td mat-cell *matCellDef="let stockitem; let rowIdx = dataIndex" class="stockitemTD" [attr.colspan]="displayedStockItems.length">
        <div [ngClass]="stockItemExpanded == stockitem ? 'example-element-detail example-element-detail-overflow' : 'example-element-detail'" [@detailExpand]="stockItemExpanded == stockitem ? 'expanded' : 'collapsed'" *ngIf="stockItemExpanded == stockitem">
          <div class="detail-view-container">
            <app-board-tally-record-history-list #boardTallyRecordHistoryApp [datasource]="boardTallyRecordHistory" (nextPage)="turnNextPage($event)"></app-board-tally-record-history-list>
          </div>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedStockItems; sticky: true" class="stock-item-row-header"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedStockItems;let stockitem; let idx = dataIndex;" id="locationrow{{idx}}" (click)="expandElementAndSetColor(stockitem,idx)" [ngClass]="stockitem.guBoardTallyRecordId == selectedRow ? 'activeTR' : ''">
    </tr>

    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" id="expandedRow" class="example-detail-row"></tr>

  </table>
  <div class="no-stock-items" *ngIf="datasource == null || datasource.data == null"><h7>There are no stock items</h7></div>
</div>
