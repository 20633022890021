export interface Printer
{
	blnActive: boolean;
	blnSensativeData: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	fltHeight: number;
	fltWidth: number;
	guPrinterLocationId: string;
	intPaperKind: number;
	intSizeUnit: number;
	intType: number;
	rowguid: string;
	txtLocation: string;
	txtName: string;
	txtPrinterIPAddress: string;
}

export class Printer
{
	blnActive: boolean;
	blnSensativeData: boolean;
	dteDateCreated: Date;
	dteLastUpdated: Date;
	fltHeight: number;
	fltWidth: number;
	guPrinterLocationId: string;
	intPaperKind: number;
	intSizeUnit: number;
	intType: number;
	rowguid: string;
	txtLocation: string;
	txtName: string;
	txtPrinterIPAddress: string;
}

export namespace Printer
{
	export enum enPaperSizeUnit
	{
		MM,
		CM,
		Inches,
		HundredthsOfInch
	}
}

export namespace Printer
{
	export enum enType
	{
		Normal,
		Receipt,
		Mobile,
		Sticker
	}
}

