<div class="final-inspection-list-container">
  <app-app-user-column-set-bar [componentColumns]="displayedfinalInspections" [pageName]="'Final Inspections'" [container]="'finalInspectionsContainer'" [columnListId]="'columnList'" (updatedComponentColumnsEvent)="updatedComponentColumnsEvent($event)"></app-app-user-column-set-bar>

  <table mat-table #tblFinalInspections [dataSource]="datasource" multiTemplateDataRows class="mat-elevation-z8">

    <ng-container matColumnDef="intFinalNo">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH"> Final Number </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD finalInspectionNumber">
        {{finalInspection.intFinalNo}}
        <mat-icon matTooltip="Copy To Clipboard" class="help-icon small-icon" (click)="copyToClipboard(finalInspection.intFinalNo.toString())" *ngIf="finalInspection != null">content_copy</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="dteDateInspected">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH"> Date </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD finalInspectionDate"> {{finalInspection.dteDateInspected | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="txtCustomerLocation">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH finalInspectionCustomerLocationTH"> Customer - Location </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD finalInspectionCustomerLocation"> {{finalInspection.blnParkedFinal == true ? finalInspection.txtElectricalAuthoritySpec != null && finalInspection.txtElectricalAuthoritySpec != "" ? finalInspection.txtElectricalAuthoritySpec + ' Spec' : finalInspection.txtParkedSpec : customerLocation(finalInspection)}} </td>
    </ng-container>

    <ng-container matColumnDef="txtOrderNo">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH"> Order No </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD finalInspectionOrderNo">
        {{finalInspection.txtOrderNo}}
        <mat-icon matTooltip="Copy To Clipboard" class="help-icon small-icon" (click)="copyToClipboard(finalInspection.txtOrderNo.toString())" *ngIf="finalInspection != null">content_copy</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="intTotalPoleQuantity">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH alignRightTH"> Quantity </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD poleQuantity alignRightTD"> {{finalInspection.intTotalPoleQuantity}} </td>
    </ng-container>

    <ng-container matColumnDef="fltTotalActualM3">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH"> M3 </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD finalM3 alignRight"> {{finalInspection.fltTotalActualM3 != null ? finalInspection.fltTotalActualM3.toFixed(2) : ''}} </td>
    </ng-container>

    <ng-container matColumnDef="fltTotalActualTonne">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH"> Tonne </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD finalTonne alignRight"> {{finalInspection.fltTotalActualTonne != null ? finalInspection.fltTotalActualTonne.toFixed(2) : ''}} </td>
    </ng-container>

    <ng-container matColumnDef="txtStatus">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH"> Status </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD status"> {{finalInspection.txtStatus}} </td>
    </ng-container>

    <ng-container matColumnDef="txtDeliveryStatus">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH"> Delivery Status </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD deliverystatus"> {{finalInspection.txtDeliveryStatus == null ? 'Non Delivered' : finalInspection.txtDeliveryStatus}} </td>
    </ng-container>

    <ng-container matColumnDef="intLoadNo">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH alignRightTH"> Load </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD loadNo alignRightTD"> {{finalInspection.intLoadNo}} </td>
    </ng-container>

    <ng-container matColumnDef="txtSeparator">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH separatorTH"></th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD separator alignRightTD"> of </td>
    </ng-container>

    <ng-container matColumnDef="intMaxLoads">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH alignRightTH"> Loads </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD maxLoads alignRightTD"> {{finalInspection.intMaxLoads}} </td>
    </ng-container>

    <ng-container matColumnDef="intMinPoleLength">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH alignRightTH"> Min Length </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD minPoleLength alignRightTD"> {{finalInspection.intMinPoleLength}} </td>
    </ng-container>

    <ng-container matColumnDef="intMaxPoleLength">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH alignRightTH"> Max Length </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD maxPoleLength alignRightTD"> {{finalInspection.intMaxPoleLength}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditFinalInspection">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH finalInspectionEditTH">  </th>
      <td mat-cell *matCellDef="let finalInspection; let rowIdx = dataIndex" class="finalInspectionTD finalInspectionEdit">
        <button class="edit-final-inspection-button" mat-flat-button color="primary" (click)="editFinalInspection(finalInspection, rowIdx)">Edit</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnSetStatus">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH finalInspectionSetStatusTH">  </th>
      <td mat-cell *matCellDef="let finalInspection; let rowIdx = dataIndex" class="finalInspectionTD finalInspectionSetStatus">
        <button class="set-status-button" mat-flat-button color="primary" (click)="setStatusReadyForLoading(finalInspection, rowIdx)" [disabled]="finalInspection.intStatus > 0">Complete Final</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnSetComplete">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH finalInspectionSetCompleteTH">  </th>
      <td mat-cell *matCellDef="let finalInspection; let rowIdx = dataIndex" class="finalInspectionTD finalInspectionSetComplete">
        <button class="set-status-button" mat-flat-button color="primary" (click)="setStatusComplete(finalInspection, rowIdx)" [disabled]="finalInspection.intStatus == 3">Set Complete</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnAddFinalInspectionItem">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH finalInspectionAddItemTH">  </th>
      <td mat-cell *matCellDef="let finalInspection; let rowIdx = dataIndex" class="finalInspectionTD finalInspectionAddItem">
        <button class="edit-final-inspection-button" mat-flat-button color="primary" (click)="addFinalInspectionItem(finalInspection, rowIdx)">Add Item</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnRemoveFinalInspection">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH finalInspectionRemoveTH">  </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD finalInspectionRemove">
        <button class="remove-final-inspection-button" mat-flat-button color="primary" (click)="removeFinalInspection(finalInspection)">Remove</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnPrintFinalInspection">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH finalInspectionPrintTH">  </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD finalInspectionPrint">
        <button class="print-final-inspection-button" mat-flat-button color="primary" (click)="printFinalInspection(finalInspection)">Print</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnDownloadFinalInspection">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH finalInspectionDownloadTH">  </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD finalInspectionDownload">
        <button class="download-final-inspection-button" mat-flat-button color="primary" (click)="createFinalPDF(finalInspection)">Download</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnCreateLoadingDocket">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH finalInspectionViewLoadingDocketTH">  </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD finalInspectionViewLoadingDocket">
        <button class="view-loading-docket-button" mat-flat-button color="primary" (click)="createLoadingDocket(finalInspection)">Create Loading Docket</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnAddToDeliveryDocket">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH finalInspectionAddToDeliveryDocketTH">  </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD finalInspectionAddToDeliveryDocket">
        <button class="add-to-delivery-docket-button" mat-flat-button color="primary" (click)="addToDeliveryDocket(finalInspection)">Add To Delivery Docket</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnCreateDeliveryDocket">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH finalInspectionCreateDeliveryDocketTH">  </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD finalInspectionCreateDeliveryDocket">
        <button class="add-to-delivery-docket-button" mat-flat-button color="primary" (click)="createDeliveryDocket(finalInspection)">Create Delivery Docket</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnCreateDiscCSV">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH finalInspectionCreateDiscCSVTH">  </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD finalInspectionCreateDiscCSV">
        <button class="create-disc-csv-button" mat-flat-button color="primary" (click)="createDiscCSV(finalInspection)">Create Disc CSV</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnCreateSelectedDiscCSV">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH finalInspectionCreateDiscCSVTH">  </th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD finalInspectionCreateDiscCSV">
        <button class="create-disc-csv-button" mat-flat-button color="primary" (click)="createSelectedDiscCSV(finalInspection)">Create Selected Disc CSV</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnViewFinalInspectionItems">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH" aria-label="row actions"></th>
      <td mat-cell *matCellDef="let finalInspection" class="finalInspectionTD">
        <button mat-icon-button aria-label="expand row" (click)="expandElement(finalInspection);$event.stopPropagation()">
          <mat-icon *ngIf="finalInspectionExpanded !== finalInspection">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="finalInspectionExpanded === finalInspection">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
      <th mat-header-cell *matHeaderCellDef class="finalInspectionTH" [attr.colspan]="displayedfinalInspections.length"></th>
      <td mat-cell *matCellDef="let finalInspection; let rowIdx = dataIndex" class="finalInspectionTD" [attr.colspan]="displayedfinalInspections.length">
        <div class="example-element-detail" [@detailExpand]="finalInspectionExpanded == finalInspection ? 'expanded' : 'collapsed'">
          <div class="detail-view-container">
            <div class="detail-view">
              <div class="view-type-label">View Type</div>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group app-user-column-set-radio" fxLayout="column">
                <mat-radio-button class="example-radio-button view-radio-buttons" [value]="0" name="radView" (click)="changeView(finalInspection, 0)" [checked]="viewType == 0">Detail</mat-radio-button>
                <mat-radio-button class="example-radio-button view-radio-buttons" [value]="1" name="radView" (click)="changeView(finalInspection, 1)" [checked]="viewType == 1">Summary</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="final-view">
              <app-final-inspection-item-list #appfinalinspectionitemlist (editFinalInspectionItemEvent)="editfinalInspectionItem($event, finalInspection, rowIdx)" (removeFinalInspectionItemEvent)="removefinalInspectionItem($event, finalInspection)" (transferredFinalInspectionItemEvent)="transferredfinalInspectionItem($event, finalInspection)" [datasource]="finalInspectionItems" [finalInspection]="finalInspection" [columnListId]="'columnList'+rowIdx" *ngIf="finalInspectionExpanded === finalInspection && viewType == 0"></app-final-inspection-item-list>
              <app-final-inspection-item-summary class="final-inspection-item-summary" #appFinalInspectionSummary [datasource]="finalInspectionItemSummary" [finalInspectionItemSummaryQtyTotal]="finalInspectionItemSummaryQtyTotal" [finalInspectionItemSummaryS1QtyTotal]="finalInspectionItemSummaryS1QtyTotal" [finalInspectionItemSummaryS2QtyTotal]="finalInspectionItemSummaryS2QtyTotal" [finalInspectionItemSummaryS3QtyTotal]="finalInspectionItemSummaryS3QtyTotal" [finalInspectionItemSummaryBBTQtyTotal]="finalInspectionItemSummaryBBTQtyTotal" [finalInspectionItemSummaryM3Total]="finalInspectionItemSummaryM3Total" [finalInspectionItemSummaryTonneTotal]="finalInspectionItemSummaryTonneTotal" *ngIf="viewType == 1"></app-final-inspection-item-summary>
            </div>
          </div>
        </div>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedfinalInspections"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedfinalInspections;let finalInspection; let idx = dataIndex;" id="locationrow{{idx}}" (click)="expandElementAndSetColor(finalInspection,idx)" [class.example-expanded-row]="deliveryDocketExpanded === deliveryDocket" [ngClass]="(finalInspection.blnParkedFinal != null && finalInspection.blnParkedFinal == true) ? 'parked-final' : finalInspection.rowguid == selectedRow ? 'activeTR' : ''">
    </tr>

    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" id="expandedRow" class="example-detail-row"></tr>

  </table>
  <div class="no-stock-items" *ngIf="datasource == null"><h7>There are no final inspections</h7></div>
</div>

<div class="final-inspection-print-wrapper">
  <div id="finalInspectionPDF" class="final-inspection-print-container">
    <app-final-inspection-item-print #finalInspectionItemPrintApp [finalInspectionIdInput]="finalInspectionPDFId" [printPDFMode]="true"></app-final-inspection-item-print>
  </div>
</div>
