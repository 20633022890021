<div class="loading-docket-list-container">
  <app-app-user-column-set-bar [componentColumns]="displayedColumns" [pageName]="columnBarPage" [container]="columnBarContainer" [columnListId]="columnListId" (updatedComponentColumnsEvent)="updatedComponentColumnsEvent($event)" *ngIf="loadedComponent"></app-app-user-column-set-bar>

  <table mat-table #tblLoadingDocket [dataSource]="datasource" multiTemplateDataRows class="mat-elevation-z8 loading-docket-list-table">

    <ng-container matColumnDef="intDocketNo">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH alignRight"> Docket No </th>
      <td mat-cell *matCellDef="let loadingDocket" class="docketNo loadingDocketTD alignRight"> {{loadingDocket.intDocketNo}} </td>
    </ng-container>


    <ng-container matColumnDef="dteExpectedLoadingDate">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH alignRight"> Expected Loading Date </th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD dateExpectedLoading alignRight"> {{loadingDocket.dteExpectedLoadingDate | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditExpectedLoadingDate">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"> </th>
      <td mat-cell *matCellDef="let loadingDocket; let idx = dataIndex;" class="loadingDocketTD editExpectingLoadingDate">
        <mat-icon matTooltip="Edit Expected Loading Date" class="edit-note-icon mouse-pointer" (click)="openExpectedLoadingDateSelector(loadingDocket, idx)">edit_note</mat-icon>
        <div id="expecting-loading-date-selector-location{{idx}}"></div>
        <div id="expectingLoadingDateSelector{{idx}}" [ngClass]="loadingDocket.rowguid == selectedExpectedLoadingDate ? 'date-selector-transition' : 'date-selector'">
          <app-date-selector *ngIf="loadingDocket.rowguid == selectedExpectedLoadingDate" [inputDate]="loadingDocket.dteExpectedLoadingDate" (outputDateEvent)="setExpectedLoadingDate(loadingDocket, $event)" (cancelEvent)="closeExpectedLoadingDate()"></app-date-selector>
        </div>
      </td>
    </ng-container>


    <ng-container matColumnDef="dteExpectedDeliveryDate">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH alignRight"> Expected Delivery Date </th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD dateExpectedDelivery alignRight"> {{loadingDocket.dteExpectedDeliveryDate | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditExpectedDeliveryDate">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"> </th>
      <td mat-cell *matCellDef="let loadingDocket; let idx = dataIndex;" class="loadingDocketTD editExpectingDeliveryDate editIcon">
        <mat-icon matTooltip="Edit Expected Delivery Date" class="edit-note-icon mouse-pointer" (click)="openExpectedDeliveryDateSelector(loadingDocket, idx)">edit_note</mat-icon>
        <div id="expecting-delivery-date-selector-location{{idx}}"></div>
        <div id="expectingDeliveryDateSelector{{idx}}" [ngClass]="loadingDocket.rowguid == selectedExpectedDeliveryDate ? 'date-selector-transition' : 'date-selector'">
          <app-date-selector *ngIf="loadingDocket.rowguid == selectedExpectedDeliveryDate" [inputDate]="loadingDocket.dteExpectedDeliveryDate" (outputDateEvent)="setExpectedDeliveryDate(loadingDocket, $event)" (cancelEvent)="closeExpectedDeliveryDate()"></app-date-selector>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="dteDateDispatched">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH alignRight"> Dispatched Date </th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD dateDispatched alignRight"> {{loadingDocket.dteDateDispatched | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditDateDispatched">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"> </th>
      <td mat-cell *matCellDef="let loadingDocket; let idx = dataIndex;" class="loadingDocketTD editDateDispatched editIcon">
        <mat-icon matTooltip="Edit Dispatched Date" class="edit-note-icon mouse-pointer" (click)="openDispatchedDateSelector(loadingDocket, idx)">edit_note</mat-icon>
        <div id="dispatched-date-selector-location{{idx}}"></div>
        <div id="dispatchedDateSelector{{idx}}" [ngClass]="loadingDocket.rowguid == selectedDispatchedDate ? 'date-selector-transition' : 'date-selector'">
          <app-date-selector *ngIf="loadingDocket.rowguid == selectedDispatchedDate" [inputDate]="loadingDocket.dteDateDispatched" (outputDateEvent)="setDispatchedDate(loadingDocket, $event)" (cancelEvent)="closeDispatchedDate()"></app-date-selector>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="dteDateLoaded">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH alignRight"> Date Loaded </th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD dateLoaded alignRight"> {{loadingDocket.dteDateLoaded | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditLoadDate">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"> </th>
      <td mat-cell *matCellDef="let loadingDocket; let idx = dataIndex;" class="loadingDocketTD editLoadDate">
        <mat-icon matTooltip="Edit Load Date" class="edit-note-icon mouse-pointer" (click)="openDateSelectorDialog(loadingDocket, idx)">edit_note</mat-icon>
        <div id="loaded-date-selector-location{{idx}}"></div>
        <div id="loadedDateSelector{{idx}}" [ngClass]="loadingDocket.rowguid == selectedLoadingDate ? 'date-selector-transition' : 'date-selector'">
          <app-date-selector *ngIf="loadingDocket.rowguid == selectedLoadingDate" [inputDate]="loadingDocket.dteDateLoaded" (outputDateEvent)="setLoadingDate(loadingDocket, $event)" (cancelEvent)="closeLoadingDate()"></app-date-selector>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="txtHaulingCompanyName">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"> Hauling Company </th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD haulingCompanyName">{{loadingDocket.txtHaulingCompanyName}}</td>
    </ng-container>

    <ng-container matColumnDef="txtHaulingCompanyDriverName">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"> Driver </th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD haulingCompanyDriverName">{{loadingDocket.txtHaulingCompanyDriverFirstName}} {{loadingDocket.txtHaulingCompanyDriverLastName}}</td>
    </ng-container>

    <ng-container matColumnDef="txtTrailer">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"> Trailer </th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD trailer">{{loadingDocket.txtTrailerAbbreviation != "" ? loadingDocket.txtTrailerAbbreviation : loadingDocket.txtTrailer}}</td>
    </ng-container>

    <ng-container matColumnDef="btnEditTruckTrailer">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"> </th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD editTruckTrailer">
        <mat-icon matTooltip="Add/Edit Truck/Trailer Combination" class="edit-note-icon mouse-pointer" (click)="openEquipmentDialog(loadingDocket)">edit_note</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="dteDateCreated">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH dateCreatedTH alignRight"> Date Created </th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD createdDate alignRight"> {{loadingDocket.dteDateCreated | date: 'dd/MM/yyyy'}} </td>
    </ng-container>

    <ng-container matColumnDef="txtOrderNo">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"> Order No </th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD orderNo"> {{loadingDocket.txtOrderNo}} </td>
    </ng-container>

    <ng-container matColumnDef="txtCustomerLocation">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"> Customer - Location </th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD customerLocation"> {{loadingDocket.txtCustomerLocation}} </td>
    </ng-container>

    <ng-container matColumnDef="btnEditDeliveryAddress">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"> </th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD editDeliveryAddress">
        <mat-icon matTooltip="Edit Delivery Address" class="edit-note-icon mouse-pointer" (click)="openDeliveryAddressDialog(loadingDocket)">edit_note</mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="txtDestination">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"> Destination </th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD destination"> {{loadingDocket.txtLocation}} </td>
    </ng-container>

    <ng-container matColumnDef="intMinPoleLength">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH alignRight"> Min Length </th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD minPoleLength alignRight"> {{loadingDocket.intMinPoleLength}} </td>
    </ng-container>

    <ng-container matColumnDef="intMaxPoleLength">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH alignRight"> Max Length </th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD maxPoleLength alignRight"> {{loadingDocket.intMaxPoleLength}} </td>
    </ng-container>

    <ng-container matColumnDef="intLoadNo">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH alignRight"> Load </th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD loadNo alignRight"> {{loadingDocket.intLoadNo}} </td>
    </ng-container>

    <ng-container matColumnDef="loadNoSeparator">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"></th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD separator"> of </td>
    </ng-container>

    <ng-container matColumnDef="intMaxLoads">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH alignRight"> Loads </th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD maxLoads alignRight"> {{loadingDocket.intMaxLoads}} </td>
    </ng-container>

    <ng-container matColumnDef="txtComments">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"> Comments </th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD comments"> {{loadingDocket.txtComments}} </td>
    </ng-container>

    <ng-container matColumnDef="txtStatus">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"> Status </th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD orderNo"> {{loadingDocket.txtStatus}} </td>
    </ng-container>

    <ng-container matColumnDef="fltActualM3">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH alignRight"> Actual M3 </th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD actualM3 alignRight"> {{loadingDocket.fltActualM3 | number : '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="fltActualTonne">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH alignRight"> Actual Tonne <span class="total-tonne"> {{totalTonne}}</span></th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD actualTonne alignRight"> {{loadingDocket.fltActualTonne | number : '1.2-2'}} </td>
    </ng-container>

    <ng-container matColumnDef="btnSetAsScheduled">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"></th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD"><button class="set-as-scheduled-button" type="button" mat-flat-button color="primary" (click)="setAsScheduled(loadingDocket)">Set As Scheduled</button></td>
    </ng-container>

    <ng-container matColumnDef="btnSetAsLoaded">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"></th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD"><button class="set-as-loaded-button" type="button" mat-flat-button color="primary" (click)="setAsLoaded(loadingDocket)">Set As Loaded</button></td>
    </ng-container>

    <ng-container matColumnDef="btnSetAsDispatched">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"></th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD">
        <button class="set-as-dispatched-button" type="button" mat-flat-button color="primary" (click)="setAsDispatched(loadingDocket)">Set As Dispatched</button>
        <div id="dispatchedDateSelector{{idx}}" [ngClass]="loadingDocket.rowguid == selectedDispatchedDate ? 'date-selector-transition' : 'date-selector'">
          <app-date-selector *ngIf="loadingDocket.rowguid == selectedDispatchedDate" [inputDate]="loadingDocket.dteDateDispatched" (outputDateEvent)="setDispatchedDate(loadingDocket, $event)" (cancelEvent)="closeDispatchedDate()"></app-date-selector>
        </div>

      </td>
    </ng-container>

    <ng-container matColumnDef="btnSetAsComplete">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"></th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD"><button class="set-as-scheduled-button" type="button" mat-flat-button color="primary" (click)="setAsComplete(loadingDocket)">Set As Complete</button></td>
    </ng-container>

    <ng-container matColumnDef="btnSetAsReadyForLoading">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"></th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD"><button class="set-as-scheduled-button" type="button" mat-flat-button color="primary" (click)="setAsReadyForLoading(loadingDocket)">Set As Ready For Loading</button></td>
    </ng-container>

    <ng-container matColumnDef="btnEditLoadingDocket">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"></th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD"><button class="edit-loading-docket-button" type="button" mat-flat-button color="primary" (click)="editLoadingDocket(loadingDocket)">Edit</button></td>
    </ng-container>

    <ng-container matColumnDef="btnRemoveLoadingDocket">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"></th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD"><button class="remove-loading-docket-button" type="button" mat-flat-button color="primary" (click)="removeLoadingDocket(loadingDocket)">Remove</button></td>
    </ng-container>

    <ng-container matColumnDef="btnAddDeliveryDockets">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"></th>
      <td mat-cell *matCellDef="let loadingDocket; let rowIdx = index;" class="loadingDocketTD"><button class="add-loading-docket-items-button" type="button" mat-flat-button color="primary" (click)="addDeliveryDockets(loadingDocket, rowIdx)">Add Delivery Dockets</button></td>
    </ng-container>

    <ng-container matColumnDef="btnDownloadLoadingDocket">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"></th>
      <td mat-cell *matCellDef="let loadingDocket; let rowIdx = index;" class="loadingDocketTD"><button class="add-download-loading-docket-button" type="button" mat-flat-button color="primary" (click)="createLoadingDocketPDF(loadingDocket, rowIdx, 'normal')">Download Loading Docket</button></td>
    </ng-container>

    <ng-container matColumnDef="btnDownloadPoleLoadingDocket">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"></th>
      <td mat-cell *matCellDef="let loadingDocket; let rowIdx = index;" class="loadingDocketTD"><button class="add-download-loading-docket-button" type="button" mat-flat-button color="primary" (click)="downloadLoadingDocket(loadingDocket, rowIdx, 'pole')">Download Pole Loading Docket</button></td>
    </ng-container>

    <ng-container matColumnDef="btnPrintLoadingDocket">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"></th>
      <td mat-cell *matCellDef="let loadingDocket; let rowIdx = index;" class="loadingDocketTD"><button class="print-loading-docket-button" type="button" mat-flat-button color="primary" (click)="printLoadingDocket(loadingDocket)">Print Loading Docket</button></td>
    </ng-container>

    <ng-container matColumnDef="btnEmailLoadingDocket">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH"></th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD" #emailButtonTD>
        <div matTooltip="To enable the Eamil button you must be logged on to Office 365" [matTooltipDisabled]="ms_authenticated">
          <button #emailButton class="email-loading-docket-button" type="button" mat-flat-button color="primary" (click)="emailLoadingDocket(loadingDocket, emailButtonTD)" [disabled]="!ms_authenticated">Email</button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="btnViewDeliveryDockets">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH" aria-label="row actions"></th>
      <td mat-cell *matCellDef="let loadingDocket" class="loadingDocketTD">
        <button mat-icon-button aria-label="expand row" (click)="expandElement(loadingDocket,$event);$event.stopPropagation()">
          <mat-icon *ngIf="loadingDocketExpanded !== loadingDocket">keyboard_arrow_down</mat-icon>
          <mat-icon *ngIf="loadingDocketExpanded === loadingDocket">keyboard_arrow_up</mat-icon>
        </button>
      </td>
    </ng-container>


    <ng-container matColumnDef="expandedDetail">
      <th mat-header-cell *matHeaderCellDef class="loadingDocketTH" [attr.colspan]="displayedColumns.length"></th>
      <td mat-cell *matCellDef="let loadingDocket; let rowIdx = dataIndex" class="loadingDocketTD loadingDocketExpandTD" [attr.colspan]="displayedColumns.length">
        <div class="example-element-detail" [@detailExpand]="loadingDocketExpanded == loadingDocket ? 'expanded' : 'collapsed'">
          <div class="detail-view-container">
            <div class="detail-view">
              <div class="view-type-label">View Type</div>
              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group app-user-column-set-radio" fxLayout="column">
                <mat-radio-button class="example-radio-button view-radio-buttons" [value]="0" name="radView" (click)="changeView(loadingDocket, 0)" [checked]="viewType == 0">Loading View</mat-radio-button>
                <mat-radio-button class="example-radio-button view-radio-buttons" [value]="1" name="radView" (click)="changeView(loadingDocket, 1)" [checked]="viewType == 1">Admin</mat-radio-button>
              </mat-radio-group>
            </div>
            <div class="final-view">
              <app-loading-docket-view class="loading-docket-view" #appLoadingDocketView [loadingDocketIdInput]="loadingDocket.rowguid" *ngIf="loadingDocketExpanded === loadingDocket && viewType == 0"></app-loading-docket-view>
              <app-delivery-docket-list #appdeliveryDocketlist (removeDeliveryDocketEvent)="removeDeliveryDocketItem($event, loadingDocket)" [datasource]="deliveryDockets" [columnBarPage]="'Loading Docket Delivery Docket'" [columnBarContainer]="'loadingDocketContainer'" [columnListId]="'columnList2'+rowIdx" *ngIf="loadingDocketExpanded === loadingDocket && viewType == 1"></app-delivery-docket-list>
            </div>
          </div>
        </div>
      </td>
    </ng-container>


    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true;" class="loading-docket-row-header"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;let loadingDocket; let idx = dataIndex;" id="locationrow{{idx}}" (click)="expandElementAndSetColor(loadingDocket,idx)" [ngClass]="loadingDocket.rowguid == selectedRow ? 'activeTR' : ''"></tr>

    <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" id="expandedRow" class="example-detail-row"></tr>

  </table>

</div>

<div class="loading-docket-print-wrapper">
  <div id="loadingDocketPDF" class="loading-docket-print-container">
    <app-loading-docket-print #loadingDocketPrintApp [loadingDocketIdInput]="loadingDocketPDFId" [showCheckSheet]="showCheckSheet"></app-loading-docket-print>
  </div>
</div>

<app-spinner-inline-tick #spinnerInlineTick [showSpinner]="showInlineSpinner" [showTick]="showTick" [diameter]="20" [inlineSpinnerId]="'inlineSpinnerId'" class="inline-spinner"></app-spinner-inline-tick>
