import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PoleTag } from '../../_shared/app-objects/PoleTag';
import { Printer } from '../../_shared/business-objects/printer.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { PrintService } from '../../_shared/services/app-services/print.service';
import { SharedService } from '../../_shared/services/shared.service';

@Component({
  selector: 'app-inspection-pole-barcode',
  templateUrl: './inspection-pole-barcode.component.html',
  styleUrl: './inspection-pole-barcode.component.scss'
})
export class InspectionPoleBarcodeComponent {

  treatmentPlantPrinter: Printer;
  form: FormGroup;

  constructor(private fb: FormBuilder, private sharedService: SharedService,
    @Inject('BASE_URL') baseUrl: string, private alertService: AlertService, private printService: PrintService) {

    this.form = this.fb.group({
      txtIdentifier: ['']
    });

  }

  ngOnInit(): void {

    this.setPrinter();

  }

  async setPrinter() {
    this.treatmentPlantPrinter = await this.printService.getPrinterByNamePromise("WEB-APP-TP", Printer.enType.Mobile);
    console.log("printer:", this.treatmentPlantPrinter);
  }


  printPoleBarcode() {
    //console.log(this.form.controls["txtIdentifier"].value);
    if (this.form.controls["txtIdentifier"].value == "") {
      this.alertService.openSnackBarError("You must enter a pole number", "Close", "center", "bottom", 2000, false, "");
      return false;
    }

    let poleNumber: number = this.form.controls["txtIdentifier"].value;

    let path: string = this.sharedService.PrintServerURI;
    //path = "https://localhost:44358/";

    let poleTag: PoleTag = new PoleTag();
    poleTag.Printer = this.treatmentPlantPrinter;
    poleTag.txtPoleNumber = poleNumber.toString();
    poleTag.txtSize = ""

    //console.log("printPoleTagByNumber", dataURL);
    this.printService.printInspectionPoleTag(poleTag);

  }
}
