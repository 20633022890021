import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatInput } from '@angular/material/input';
import { DomSanitizer } from '@angular/platform-browser';
import { FileAttachment } from '../../_shared/app-objects/file-attachment';
import { dtoStockItemDisplay } from '../../_shared/business-objects/dto-stock-item-display.bo';
import { AlertService } from '../../_shared/services/alert.service';
import { FileService } from '../../_shared/services/app-services/file.service';

@Component({
  selector: 'app-photo-viewer',
  templateUrl: './photo-viewer.component.html',
  styleUrl: './photo-viewer.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class PhotoViewerComponent implements OnInit {

  @Input() path: string;
  @Input() folderName: string;
  @Output() photoViewerClose = new EventEmitter<boolean>();
  @Output() emailPhotosEvent = new EventEmitter<FileAttachment[]>();

  fileAttachments: FileAttachment[];
  selectedFileAttachments: FileAttachment[] = [];
  loadFileAttachments: FileAttachment[];
  loadNumber: number;
  totalPhotos: number;

  showSpinner: boolean = false;

  constructor(private alertService: AlertService, private fileService: FileService, protected sanitizer: DomSanitizer) {

  }


  ngOnInit(): void {

    this.getPhotos();

  }

  async getPhotos() {

    if (this.path == null || this.path == "") {
      await this.alertService.openSnackBarCustomDefaultPromise("No Path", "No photo path was supplied");
      return;
    }

    this.showSpinner = true;

    //let spinner = document.getElementById("photoViewerSpinner");

    //console.log(spinner);

    let fileNames: string[] = await (await this.fileService.getFileNames(this.path + this.folderName)).filter(a => { return a.toLowerCase().indexOf("thumbs") == -1 });
    this.totalPhotos = fileNames.length;
    this.loadNumber = 0;

    //console.log("fileNames: ", fileNames);
    this.fileAttachments = [];
    this.loadFileAttachments = [];
    for (let i = 0; i <= fileNames.length - 1; i++) {
      if (fileNames[i].toLowerCase().indexOf("thumbs") == -1) {
        this.loadFileAttachments = await this.fileService.getFile(this.path + this.folderName, fileNames[i]);
        this.loadNumber = i + 1;
        this.showSpinner = false;
        this.fileAttachments.push(this.loadFileAttachments[0]);
      }
    }

    this.showSpinner = false;

    //this.fileAttachments = await this.fileService.getFiles(this.path + this.folderName);


  }

  dataURI(fileAttachment: FileAttachment) {

    return this.sanitizer.bypassSecurityTrustUrl(fileAttachment.dataURI());
  }


  async emailPhotos() {
    if (this.selectedFileAttachments.length == 0) {
      await this.alertService.openSnackBarCustomDefaultPromise("No Selected Photos", "There are no selected photos to email.\nPlease tick the tickbox to select photos to email");
      return;
    }

    this.emailPhotosEvent.emit(this.selectedFileAttachments);

  }

  closePhotos() {
    this.photoViewerClose.emit(true);
  }

  selectedPhoto(chkSelected: MatCheckbox, fileAttachment: FileAttachment) {

    if (chkSelected.checked == true) {
      this.selectedFileAttachments.push(fileAttachment);
    }

    if (chkSelected.checked == false) {
      for (let i = this.selectedFileAttachments.length - 1; i > -1; i--) {
        if (fileAttachment.fileName == this.selectedFileAttachments[i].fileName) {
          this.selectedFileAttachments.splice(i, 1);
        }
      }

    }

  }

  async deletePhotos() {

    let msg: string = await this.alertService.openSnackBarCustomPromise("Delete Photos", "Are you sure you want to delete the selected photos", "Yes", "No", "center", "bottom", "", 0, true);
    if (msg != "Yes") {
      return;
    }

    console.log("this.selectedFileAttachments:", this.selectedFileAttachments);


    let deleted: boolean = await this.fileService.deleteFiles(this.selectedFileAttachments)
    if (deleted == true) {

      await this.getPhotos();
      this.alertService.openSnackBarDefault("The photos have been removed");

    }

  }

  async savePhoto(ev) {
    console.log("saving photo");

    let saved: boolean = await this.fileService.savePhoto(ev, "mtech\\BoardPlant\\StockItemPhotos\\" + this.folderName);

    if (saved == true) {
      console.log("getPhotos");
      await this.getPhotos();
    }

  }

  openPhoto(fileAttachment: FileAttachment) {

    this.fileService.downloadAttachment(fileAttachment);

  }

}
