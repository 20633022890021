import { Printer } from "../business-objects/printer.bo";

export interface StockItemBarcode {
  txtIdentifier: string;
  txtDescription: string;
  txtLogoBase64: string;
  Printer: Printer;
}

export class StockItemBarcode {
  txtIdentifier: string;
  txtDescription: string;
  txtLogoBase64: string;
  Printer: Printer;
}
